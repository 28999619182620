import React from 'react';
import {Icon, lighten, useMediaQuery} from '@mui/material';

import {makeStyles} from '@mui/styles';
import {useTheme} from '@mui/material/styles';

import Carousel from '../common/Carousel';
import TwitterIcon from '../common/icons/TwitterIcon';
import FacebookIcon from '../common/icons/FacebookIcon';
import clsx from 'clsx';

const useStyles = makeStyles(({palette, ...theme}) => ({
    section: {
        background: `linear-gradient(45deg, ${palette.primary.main} 0%,${palette.primary.light} 100%)`,
    },
    card: {
        maxWidth: 700,
    },
    review: {
        transform: 'skewY(0.75deg)',
        transformOrigin: 'bottom left',
    },
}));

const Testimonial6 = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const testimonialList = [
        {
            title: '酒店行业联邦推荐引擎（Federated Recommender System）',
            companyLogoUrl: './assets/images/mock-logo-1.png',
            comment: `"Lorem ipsum dolor sit amet consectetur adipisicing
          elit. Fugit modi voluptas vero iusto fuga quos totam
          eius, atis magnam tempora doloribus ducimus dolorem
          culpa animi beatae tenetur! Sapiente, quia tempora."`,
            icon: TwitterIcon,
            user: {
                imageUrl: './assets/images/face-1.png',
                name: 'John Doe',
                designation: 'Product Manager',
            },
        },
        {
            title: '车联网隐私计算平台解决方案',
            companyLogoUrl: './assets/images/mock-logo-2.png',
            comment: `"Lorem ipsum dolor sit amet consectetur adipisicing
          elit. Fugit modi voluptas vero iusto fuga quos totam
          eius, atis magnam tempora doloribus ducimus dolorem
          culpa animi beatae tenetur! Sapiente, quia tempora."`,
            icon: FacebookIcon,
            user: {
                imageUrl: './assets/images/face-2.png',
                name: 'Adam Smith',
                designation: 'CEO',
            },
        },
        {
            title: '基于合成数据 (Synthetic Data) 的医疗行业隐私保护解决方案',
            companyLogoUrl: './assets/images/mock-logo-3.png',
            comment: `"Lorem ipsum dolor sit amet consectetur adipisicing
          elit. Fugit modi voluptas vero iusto fuga quos totam
          eius, atis magnam tempora doloribus ducimus dolorem
          culpa animi beatae tenetur! Sapiente, quia tempora."`,
            icon: TwitterIcon,
            user: {
                imageUrl: './assets/images/face-3.png',
                name: 'John White',
                designation: 'Software Engineer',
            },
        },
    ];

    return (
        <div className={clsx('section', classes.section)} id="testimonial6">
            <div className="container text-center">
                <h1 className="font-normal text-44 mt-0 text-white mx-auto mb-16">
                    典型案例
                </h1>
                <Carousel
                    bulletColor={lighten(theme.palette.primary.main, 0.54)}
                    slidesPerView={1}
                    spacing={0}
                    navigation={false}
                    paginationClass="mt-16"
                    carouselId="swiper-6"
                >
                    {testimonialList.map((testimonial, index) => (
                        <div className={clsx('mx-auto', classes.card)} key={index}>
                            <div className={clsx({
                                'flex justify-center': true,
                                'flex-wrap': isMobile,
                            })}>
                                <p className={clsx({
                                    'text-white': true,
                                    'text-left my-0 ml-8': !isMobile,
                                    [classes.review]: true,
                                })}>
                                    {testimonial.title}
                                </p>
                            </div>
                            <div className="flex flex-wrap mt-4 justify-center mb-2">
                                {[1, 2, 3, 4, 5].map((i) => (
                                    <Icon
                                        key={i}
                                        className="mx-1"
                                        fontSize="small"
                                        color="secondary"
                                    >
                                        start
                                    </Icon>
                                ))}
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default Testimonial6;
