import React, {useEffect} from 'react';
import {scrollTo} from '../utils';
import Intro6 from './sections/Intro6';
import Blog1 from './sections/Blog1';
import TopBar6 from './sections/TopBar6';
import Services8 from './sections/Services8';
import Testimonial6 from './sections/Testimonial6';
import Subscription1 from './sections/Subscription1';

const Landing6 = () => {
    useEffect(() => {
        scrollTo('root');
    });

    return (
        <div className="landing">
            <TopBar6/>
            {/*首页*/}
            <Intro6/>

            {/*产品服务*/}
            <Services8/>

            {/*典型案例*/}
            <Testimonial6/>

            {/*公司简介*/}
            <Blog1/>

            {/*联系我们*/}
            <Subscription1/>

            {/*<Footer2/>*/}
        </div>
    );
};

export default Landing6;
