import React, {useEffect, useState} from 'react';
import {classList, debounce} from '../../utils';
import {Icon, IconButton} from '@mui/material';
import ScrollTo from '../common/ScrollTo';

const TopBar6 = (props) => {
    const [isTop, setIsTop] = useState(true);
    const [isClosed, setIsClosed] = useState(true);

    let scrollableElement = document.querySelector('.scrollable-content');
    if (!scrollableElement) scrollableElement = window;

    let handleScrollRef = null;
    let toggleIcon = isClosed ? 'menu' : 'close';

    const handleScroll = () => {
        return debounce(({target: {scrollTop}}) => {
            let isCurrentTop = scrollTop < 100 || scrollableElement.scrollY < 100;
            setIsTop(isCurrentTop);
        }, 20);
    };

    handleScrollRef = handleScroll();

    const close = () => {
        setIsClosed(false);
    };

    useEffect(() => {
        if (scrollableElement) {
            scrollableElement.addEventListener('scroll', handleScrollRef);
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener('scroll', handleScrollRef);
            }
        };
    }, [scrollableElement, handleScrollRef]);

    return (
        <section
            className={classList({
                header: true,
                'header-fixed': !isTop,
                closed: isClosed,
            })}
        >
            <div className="container header-container">
                <div className="brand">
                    <img src="./assets/images/react-logo.svg" alt=""/>
                </div>
                <ul className="navigation">
                    <li>
                        <ScrollTo to="intro6" onScroll={close}>
                            首页
                        </ScrollTo>
                    </li>
                    <li>
                        <ScrollTo to="services8" onScroll={close}>
                            产品服务
                        </ScrollTo>
                    </li>
                    <li>
                        <ScrollTo to="testimonial6" onScroll={close}>
                            典型案例
                        </ScrollTo>
                    </li>
                    <li>
                        <ScrollTo to="blog1" onScroll={close}>
                            公司简介
                        </ScrollTo>
                    </li>
                    <li>
                        <ScrollTo to="subscription1" onScroll={close}>
                            联系我们
                        </ScrollTo>
                    </li>
                </ul>
                <div className="m-auto"/>
                <IconButton
                    className="header__toggle"
                    onClick={() => {
                        setIsClosed(!isClosed);
                    }}
                >
                    <Icon>{toggleIcon}</Icon>
                </IconButton>
            </div>
        </section>
    );
};

export default TopBar6;
