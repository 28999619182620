import React from 'react';

import {ThemeProvider} from '@mui/material/styles';
import Scrollbar from 'react-perfect-scrollbar';
import {Theme} from './theme';

import 'react-perfect-scrollbar/dist/css/styles.css';
import GlobalCss from './styles/jss/GlobalCss';
import Landing6 from './home/Landing6';

function App() {
    return (
        <ThemeProvider theme={Theme}>
            <GlobalCss>
                <Scrollbar
                    className="h-full-screen scrollable-content"
                    option={{suppressScrollX: true}}
                >
                    <Landing6/>
                </Scrollbar>
            </GlobalCss>
        </ThemeProvider>
    );
}

export default App;
