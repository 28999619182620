import React from 'react';
import {Map, Marker} from 'react-amap';

const Address = (props) => {
    const mapKey = 'cc4e17e135c9d3fa1607edcf162830b5'; //需要自己去高德官网上去申请
    // 29.632651721229948, 106.4852832733055
    const markerPosition = {longitude: 106.4852832733055, latitude: 29.632651721229948};
    const centerPosition = {longitude: 105, latitude: 28};

    return (
        <div style={{width: '100%', height: 400}}>
            <Map
                plugins={['ToolBar', 'Scale']}
                center={centerPosition}
                zoom={6}
            >
                <Marker position={markerPosition}/>
            </Map>
        </div>
    );
};

export default Address;
