import React from 'react';
import {Grid} from '@mui/material';
import StrategyIcon from '../common/icons/StrategyIcon';
import PenIcon from '../common/icons/PenIcon';
import ManagementIcon from '../common/icons/ManagementIcon';
import Feature4IconCreator from '../common/icons/Feature4IconCreator';

const Services8 = () => {
    const serviceList = [
        {
            title: '隐私计算软件',
            icon: StrategyIcon,
            subtitle: '综合以下核心技术提供安全高效的隐私保护能力',
            features: [
                '联邦学习：通过分布式机器学习技术，允许数据在本地进行计算，而不需要集中存储，确保数据的隐私性和安全性。',
                '差分隐私：通过添加噪声来保护个人数据的隐私，使得从统计信息中无法识别出单个个体的信息。',
                '多方安全计算：允许多方在不直接共享数据的情况下，进行数据计算和分析，确保数据隐私。',
                '同态加密：使得数据在加密状态下可以进行计算，结果解密后与原始数据计算结果一致，保障数据安全。',
                '合成数据：通过算法生成的数据，可以在不泄露原始数据的情况下用于研究和分析',
            ],
        },
        {
            title: '大数据分析及AI智能服务',
            icon: PenIcon,
            features: [
                '大语言模型（LLM）：基于大量文本数据训练的模型，能够理解和生成人类语言，为各种应用提供强大的语言处理能力。',
                '机器学习（ML）：使用算法对数据进行分析和解释，自动改进模型的性能，为多类型任务提供智能解决方案。',
                '运筹优化（OR）：通过数学模型和算法来解决复杂的决策问题，提高资源的利用效率。',
                '可视化（Visualization）：将复杂的数据转化为直观的图形，帮助用户更好地理解和分析数据。',
                '离散事件仿真（Simulation）：模拟真实世界的操作，帮助企业预测和改进系统的性能。',
                '规则引擎（CEP）：自动化决策过程，根据预定的规则对数据进行实时分析和响应。',
            ],
        },
        {
            title: '行业解决方案',
            icon: ManagementIcon,
            features: [
                '金融行业：提供安全的数据分析和交易解决方案，帮助金融机构提高效率和减少风险。',
                '医疗行业：确保患者数据的隐私和安全，同时提供高效的医疗分析和诊断解决方案。',
                '制造行业：通过先进的数据分析和仿真技术，在保护隐私的前提下提高生产效率和产品质量。',
                '消费者行业：利用自我研发的联邦学习推荐引擎，在符合数据隐私保护法的前提下，实现精准推荐，提升用户体验。',
                '汽车行业：利用运筹优化学和机器学习等前沿技术，为汽车生产供应链系统提供高效、精准的解决方案。',
            ],
        },
    ];

    return (
        <section className="section" id="services8">
            <div className="container">
                <Grid container spacing={6} justify="space-between">
                    {serviceList.map((item, ind) => (
                        <Grid key={ind} item sm={4} xs={12}>
                            <div className="text-center max-w-252 mx-auto">
                                <Feature4IconCreator>
                                    <item.icon color="primary" className="text-72"/>
                                </Feature4IconCreator>
                                <h5 className="mt-8 font-medium">{item.title}</h5>
                                <ul className="max-w-400">
                                    {
                                        item.features.map((d, i) => (<li key={`${item.title}-${i}`}>{d}</li>))
                                    }
                                </ul>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </section>
    );
};

export default Services8;
