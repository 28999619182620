import React from 'react';
import Address from './Address';

const Subscription1 = () => {
    return (
        <section className="section" id="subscription1">
            <div className="container">
                <div className="max-w-770 mx-auto text-center">
                    <h1 className="text-44 mt-0 font-normal mb-6">
                        联系我们
                    </h1>
                    <p className="mb-16 max-w-400 mx-auto">
                        地址：重庆市两江新区康美街道卉竹路2号4幢8层810号
                    </p>
                    <Address/>
                </div>
            </div>
        </section>
    );
};

export default Subscription1;
