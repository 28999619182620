import React from 'react';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import background from './mw960367054-Kc-fa3I9aRQ-unsplash.jpg';

const useStyles = makeStyles(({palette, ...theme}) => ({
    containerHolder: {
        backgroundImage: `url(${background})`,
        // background: `linear-gradient(45deg, ${palette.primary.main} 0%,${palette.primary.light} 100%)`,
    },
    background: {
        backgroundImage: `url(${background})`,
        // background: `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(${background}) no-repeat; cover;`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        strokeWidth: '5px',
        fontColor: 'black',
        boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.2)',
    },
    container: {
        paddingTop: '6rem !important',
        paddingBottom: '6rem !important',
    },
}));

const Intro6 = () => {
    const classes = useStyles();

    return (
        <section className="section" id="intro6">
            <div className={classes.background}>
                <div
                    className={clsx(
                        'container flex-column justify-center items-center text-white',
                        classes.container,
                    )}
                >
                    <h1 className="font-normal text-center text-48 mb-4 mt-0"
                        style={{paddingBottom: '30px'}}
                    >
                        数衍科技
                    </h1>
                    {/*Product Features*/}

                    {/*Feature 1*/}
                    <h2 className="font-normal">可用不可见</h2>
                    <h2 className="font-normal">防止数据隐私泄露</h2>
                    <p className="max-w-550 text-center text-inherit my-12">
                        隐私计算通过同态加密、联邦学习、多方安全计算等技术在保证企业在不直接接触原始数据的前提下，可以合作进行 AI 模型训练，有效防止企业 AI 合作中的隐私泄露风险
                    </p>

                    {/*Feature 2*/}
                    <h2 className="font-normal">可控可保护</h2>
                    <h2 className="font-normal">促进数据流通合作</h2>
                    <p className="max-w-550 text-center text-inherit my-12">
                        通过隐私计算技术确保在使用 AI 工具的时候，和 AI 工具的数据交互信息可控，企业及个人隐私得到可靠的保护
                    </p>

                    {/*Feature 3*/}
                    <h2 className="font-normal">可信可融合</h2>
                    <h2 className="font-normal">保障数据使用合规</h2>
                    <p className="max-w-550 text-center text-inherit my-12">
                        隐私计算可以融合区块链等技术强化在 “数字身份、算法、计算、监管” 等方面的信任机制，进一步完美数据要素的确权、定价与交易的可信体系建设
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Intro6;
