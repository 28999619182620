import React from 'react';

const Blog1 = () => {

    return (
        <section className="section section-bg-light-primary" id="blog1">
            <div className="container">
                <div className="max-w-400 mb-16 text-center mx-auto">
                    <h1 className="mt-0 font-normal text-44">公司愿景</h1>
                    <p>
                        在数字化的未来，数据隐私和安全成为个体和企业的重点关切。数衍科技致力于成为全球领先的AI隐私计算技术平台，着力创造一个在保障个人和企业数据隐私的同时，能够高效、安全地流通和利用数据的世界。我们相信，通过我们的技术和努力，可以为人类开创一个更加智能、更加安全的数字未来。
                    </p>
                </div>

                <div className="max-w-400 mb-16 text-center mx-auto">
                    <h1 className="mt-0 font-normal text-44">公司简介</h1>
                    <p>
                        数衍科技，基于前沿AI技术的一体化隐私计算服务商，专注于使用隐私计算技术保障数据的安全和高效流通。核心团队成员来自于新加坡国立大学以及一汽集团等世界五百强企业，具有深厚的技术积累，同时在汽车、医疗、半导体和人工智能等领域累积了丰富的行业资源。团队针对AI大模型时代数据隐私难题研发了一系列创新技术，已布局申请关于隐私计算和大模型的数项专利，抢占新一代隐私保护领域先发优势。我们的产品和服务可广泛应用于金融、医疗、制造等多个行业，帮助企业在确保数据隐私的基础上，充分挖掘和利用数据的价值。
                    </p>
                </div>

            </div>
        </section>
    );
};

export default Blog1;
